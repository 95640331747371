<script setup lang="ts">
import type { DepositStreakInfo, Promotion } from "@/types";

const prepareImgUrl = useImage();

const bgPromo = `url(${prepareImgUrl("/nuxt-img/deposit-streak/promo/promo-card-bg.webp", {
	format: "avif",
	loading: "lazy"
})})`;

const { t } = useT();
const { open } = useTaoModals();

const props = defineProps<{
	type?: Promotion["type"];
	image?: string;
	depositInfo: DepositStreakInfo;
	finishedAt: string;
}>();

const depositState = computed(
	() => (props.depositInfo?.maxNumberOfDeposit ?? 0) - (props.depositInfo?.currentCountDeposit ?? 0)
);

const handleHowItWorks = () => {
	open("LazyOModalDepositStreakHowItWorks");
};

const handleClick = () => {
	if (props.depositInfo?.dailyWinStreakLimitExceeded) {
		return;
	}

	if (props.depositInfo?.canWheel) {
		open("LazyOModalDepositStreak");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 244,
			height: 216,
			src: image,
			alt: 'deposit card',
			format: 'avif',
			loading: 'lazy'
		}"
		width="330px"
		height="310px"
		class="card-deposit"
		@click="handleClick"
	>
		<template #default>
			<span class="btn-info" @click.stop="handleHowItWorks">
				<ASvg name="16/info" class="icon" />
			</span>
		</template>
		<template #top>
			<AText v-if="!depositInfo?.dailyWinStreakLimitExceeded" :size="12" class="label-time">
				<i18n-t keypath="Time left {key}">
					<template #key>
						<b>{{ finishedAt }}</b>
					</template>
				</i18n-t>
			</AText>
			<AText class="text-coro title" :modifiers="['center', 'bold', 'uppercase']">
				<AText v-if="depositInfo?.canWheel" class="text-chihuahua" :modifiers="['bold', 'uppercase']">
					{{ t("All purchases done") }}
				</AText>
				<i18n-t v-else-if="depositInfo?.dailyWinStreakLimitExceeded" keypath="make {key} and">
					<template #key>
						<AText class="text-chihuahua" :modifiers="['bold', 'uppercase']">
							{{ `${depositInfo?.maxNumberOfDeposit} purchases` }}
						</AText>
					</template>
				</i18n-t>
				<i18n-t v-else keypath="make {key} and">
					<template #key>
						<AText class="text-chihuahua" :modifiers="['bold', 'uppercase']">
							<i18n-t keypath="{key1}/{key2} purchases">
								<template #key1>
									{{ depositState }}
								</template>
								<template #key2>
									{{ depositInfo?.maxNumberOfDeposit }}
								</template>
							</i18n-t>
						</AText>
					</template>
				</i18n-t>
			</AText>
		</template>
		<template #bottom>
			<div class="card-info">
				<AText :modifiers="['bold', 'uppercase', 'right', 'condensed']">
					<i18n-t keypath="get {key} up to">
						<template #key>
							<br />
						</template>
					</i18n-t>
				</AText>
				<AText type="h4">
					{{ t("% BACK", { msg: depositInfo?.maxPercentage }) }}
				</AText>
			</div>
			<AText class="promo-intro" :size="10" :modifiers="['center', 'uppercase']" as="div">
				<i18n-t keypath="Complete streak with {key1}+ purchases - get one boosted by up to {key2}%!">
					<template #key1>
						{{ depositInfo?.minDepositValue }}
					</template>
					<template #key2>
						{{ depositInfo?.maxPercentage }}
					</template>
				</i18n-t>
			</AText>
			<AButton
				variant="primary"
				size="s"
				:disabled="depositInfo?.dailyWinStreakLimitExceeded"
				:modifiers="['wide', depositInfo?.dailyWinStreakLimitExceeded ? 'disabled' : '']"
			>
				<AAnimationTada
					v-if="depositInfo?.dailyWinStreakLimitExceeded"
					:active="depositInfo?.dailyWinStreakLimitExceeded"
				>
					<NuxtIcon name="20/lock" class="btn-icon" :filled="false" />
				</AAnimationTada>
				<AText :modifiers="['uppercase', 'bold']" as="div">
					<span v-if="!depositInfo?.dailyWinStreakLimitExceeded">
						{{ depositInfo?.canWheel ? t("Get my prize") : t("Make a purchase") }}
					</span>
					<span v-else class="available">
						<NuxtIcon name="40/locked" />
						<span class="available-text">{{ t("Available Tomorrow") }}</span>
					</span>
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style lang="scss" scoped>
.card {
	background: v-bind(bgPromo) no-repeat bottom center / cover;
}
.card-deposit {
	&.default:deep(> img) {
		width: 105px;
	}

	&:deep(.bottom) {
		gap: 8px;
	}

	.title {
		max-width: 222px;
	}

	.card-info {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
	}

	.promo-intro {
		max-width: 270px;
	}

	button {
		gap: 8px;

		.icon {
			font-size: 20px;
		}
	}
}
.label-time {
	display: inline-flex;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	background: var(--a-overlay-100);
	padding: 2px 4px 0;
	color: var(--coro);
	height: 22px;
}
.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 10px;
	left: 10px;

	.icon {
		font-size: 16px;
	}
}

.available {
	display: flex;
	align-items: center;
	gap: 8px;

	&-text {
		line-height: 1;
		padding-top: 1px;
	}
}
</style>
